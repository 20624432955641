
      @import 'styles-scss/abstracts/mixins';
    
.section {
  position: relative;
  padding-block: calc(var(--space-md) * 2);

  @include break-reverse(768) {
    padding-block: calc(var(--space) * 3);
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    display: block;
    width: 100%;
    height: 1px;
    background: linear-gradient(
      90deg,
      rgb(from var(--primary-40) r g b / 0),
      var(--neutral-40) 50%,
      rgb(from var(--primary-40) r g b / 0)
    );
  }
}

.heading {
  position: relative;
  z-index: 5;
  margin-bottom: var(--space-xxl);

  @include break-reverse(768) {
    margin-bottom: calc(var(--space-md) * 2);
  }
}

.title {
  margin-bottom: var(--space);
}

.subtitle {
  color: var(--neutral-40);
}

.visual {
  @include break(767) {
    margin: 0 auto;
    width: fit-content;
    max-width: 480px;
  }
}

.actions {
  position: relative;
  z-index: 5;
  flex-direction: column;
  margin-top: var(--space-xl);

  @include break-reverse(768) {
    flex-direction: row;
  }
}

.link {
  text-align: center;
}

.wrapper__mobile {
  @include break-reverse(768) {
    display: none;
  }
}

.wrapper__desktop {
  display: none;

  @include break-reverse(767) {
    display: block;
    position: relative;
  }
}

.image {
  display: block;
  max-width: 575px;

  @include break(767) {
    margin-bottom: -36%;
  }
}

.background {
  position: absolute;

  @include break-reverse(768) {
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 80vw;
    height: auto;
    aspect-ratio: 1.59;
    opacity: 0.25;
  }

  @include break-reverse(1240) {
    width: 950px;
  }
}

.tabs {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(var(--neutral-40), var(--primary-40)) border-box;
    mask:
      linear-gradient(var(--neutral-0) 0 0) padding-box,
      linear-gradient(var(--neutral-0) 0 0);
    mask-composite: exclude;
    border: 0.8px solid transparent;
    border-radius: 10px;
  }

  flex-direction: row;
  align-items: stretch;
  gap: var(--space-xxs);
  padding: var(--space-xs);
  background: rgb(from var(--primary-70) r g b / 0.4);
  border-radius: 10px;
  backdrop-filter: blur(120px);
}

.tabs_nav {
  gap: calc(var(--space-lg) * 0.5);
  padding: var(--space-xs) calc(var(--space) * 0.25);
  background: linear-gradient(
    rgb(from var(--primary-95) r g b / 0.9),
    rgb(from var(--primary-95) r g b / 0.45)
  );
  border-radius: 10px;
}

.tabs_logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: auto;
  aspect-ratio: 1;
  padding: 0 var(--space-xxs);
  background: var(--primary-60);
  border-radius: 6px;

  @include break-reverse(992) {
    padding: 0 calc(var(--space-lg) * 0.125);
    width: 40px;
  }

  @include break-reverse(1240) {
    padding: 0 calc(var(--space) * 0.25);
    width: 48px;
  }
}

.tabs_list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: calc(var(--space) * 0.25);

  @include break-reverse(992) {
    gap: var(--space-xs);
  }

  @include break-reverse(1240) {
    gap: calc(var(--space-lg) * 0.25);
  }
}

.tabs_item {
  position: relative;
  width: 48px;
  height: auto;
  aspect-ratio: 1;

  &:has(.tabs_btn__active) {
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      width: 60px;
      height: auto;
      aspect-ratio: 1;
      pointer-events: none;

      @include break-reverse(992) {
        width: 75px;
      }

      @include break-reverse(1240) {
        width: 90px;
      }
    }

    &:before {
      z-index: 5;
      border: 1px solid var(--neutral-0);
      border-radius: 100%;
      filter: blur(8px);
    }

    &:after {
      content: url('./icons/icon-hover.svg');
    }
  }

  @include break-reverse(992) {
    width: 60px;
  }

  @include break-reverse(1240) {
    width: 72px;
  }
}

.tabs_btn {
  width: 100%;
  height: 100%;
  padding: 0;
  background: rgb(from var(--primary-70) r g b / 0.4);
  border: 1px solid var(--neutral-80);
  border-radius: 6px;
  transition: none;

  &:hover {
    background: var(--primary-40);
  }

  & > span {
    flex-direction: column;
    gap: var(--space-xxs);
  }
}

.tabs_btn__active {
  background: var(--primary-60);
  cursor: auto;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 48px;
    height: auto;
    aspect-ratio: 1;
    background: linear-gradient(
        152.67deg,
        var(--primary-75) -5.94%,
        var(--neutral-15) 55.4%,
        var(--primary-55) 82.96%
      )
      border-box;
    mask:
      linear-gradient(var(--neutral-0) 0 0) content-box,
      linear-gradient(var(--neutral-0) 0 0);
    mask-composite: exclude;
    border: 1px solid transparent;
    border-radius: 6px;

    @include break-reverse(992) {
      width: 60px;
    }

    @include break-reverse(1240) {
      width: 72px;
    }
  }

  &:hover {
    background: var(--primary-60);
  }
}

.tabs_icon {
  width: 15px;
  height: auto;
  aspect-ratio: 1;

  @include break-reverse(992) {
    width: 19px;
  }

  @include break-reverse(1240) {
    width: 23px;
  }
}

.tabs_caption {
  font-size: calc(var(--text-xs) * 0.5);
  font-weight: 500;
  word-spacing: 100rem;

  @include break-reverse(992) {
    font-size: calc(var(--text-md) * 0.5);
  }

  @include break-reverse(1240) {
    font-size: calc(var(--header-xxs-20) * 0.5);
  }
}

.card {
  background: linear-gradient(
    rgb(from var(--primary-95) r g b / 0.9),
    rgb(from var(--primary-95) r g b / 0.45)
  );
  border-radius: 10px;
}

.card_image {
  display: block;
}
