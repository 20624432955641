
      @import 'styles-scss/abstracts/mixins';
    
.section {
  position: relative;
  padding-block: calc(var(--space-md) * 2);

  @include break(575) {
    &::before,
    &::after {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 1px;
      background: linear-gradient(
        90deg,
        rgb(from var(--primary-40) r g b / 0),
        var(--neutral-40) 50%,
        rgb(from var(--primary-40) r g b / 0)
      );
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }
  }

  @include break-reverse(576) {
    padding-block: calc(var(--space-lg) * 2);
  }
}
