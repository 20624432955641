
      @import 'styles-scss/abstracts/mixins';
    
.section {
  position: relative;
  padding-block: calc(var(--space-md) * 2);

  @include break-reverse(768) {
    padding-block: calc(var(--space) * 3);
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    display: block;
    width: 100%;
    height: 1px;
    background: linear-gradient(
      90deg,
      rgb(from var(--primary-40) r g b / 0),
      var(--neutral-40) 50%,
      rgb(from var(--primary-40) r g b / 0)
    );
  }
}

.heading {
  margin-bottom: var(--space-xl);
}

.title {
  margin-bottom: var(--space);
}

.subtitle {
  max-width: 750px;
  color: var(--neutral-40);
}

.visual {
  @include break(767) {
    margin: 0 auto;
    width: fit-content;
    max-width: 480px;
  }
}

.image {
  display: flex;

  & img {
    margin: 0 auto;
  }
}

.actions {
  margin-top: var(--space-xl);

  @include break-reverse(768) {
    margin-top: var(--space);
  }
}

.link {
  max-width: 370px;
  text-align: center;
}
