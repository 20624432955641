@layer blocks {
  /* default */

  .block_default {
    display: grid;
  }

  /* solid */

  .block_solid {
    display: grid;
    justify-items: center;
    background-color: rgb(from var(--neutral-0) r g b / 0.02);
  }

  .block_solidMob {
    position: relative;
    display: grid;
    justify-items: center;
  }

  @media (min-width: 576px) {
    .block_solidMob {
      background-color: rgb(from var(--neutral-0) r g b / 0.02);
      overflow: hidden;
    }
  }

  /* shadow */

  .block_shadow {
    display: grid;
    justify-items: center;
    border: 1px solid var(--primary-60);
    box-shadow: inset 0 0 15px 0 rgb(from var(--neutral-25) r g b / 0.1);
  }

  .block_shadowMob {
    position: relative;
    display: grid;
    justify-items: center;
  }

  @media (min-width: 576px) {
    .block_shadowMob {
      border: 1px solid var(--primary-60);
      box-shadow: inset 0 0 15px 0 rgb(from var(--neutral-25) r g b / 0.1);
      overflow: hidden;
    }
  }

  .block_shadowTab {
    position: relative;
    display: grid;
    justify-items: center;
  }

  @media (min-width: 768px) {
    .block_shadowTab {
      border: 1px solid var(--primary-60);
      box-shadow: inset 0 0 15px 0 rgb(from var(--neutral-25) r g b / 0.1);
      overflow: hidden;
    }
  }

  /* gradient */

  .block_gradient {
    position: relative;
  }

  .block_gradient::before {
    content: '';
    position: absolute;
    inset: 0;
    pointer-events: none;
    background: linear-gradient(
        153deg,
        var(--primary-75) -5.94%,
        var(--neutral-15) 55.4%,
        var(--primary-55) 82.96%
      )
      border-box;
    mask:
      linear-gradient(var(--neutral-0) 0 0) padding-box,
      linear-gradient(var(--neutral-0) 0 0);
    mask-composite: exclude;
    border: 1px solid transparent;
    border-radius: 16px;
  }

  /* radius */

  .radius_sm {
    border-radius: var(--space-xs);
  }

  .radius_md {
    border-radius: var(--space-sm);
  }

  .radius_lg {
    border-radius: var(--space);
  }

  /* padding */

  .padding_base {
    padding-block: var(--space-xl);
    padding-inline: var(--space);
  }

  @media (min-width: 576px) {
    .padding_base {
      padding-block: calc(var(--space-md) * 2);
    }
  }

  @media (min-width: 768px) {
    .padding_base {
      padding-block: calc(var(--space) * 3);
    }
  }

  @media (min-width: 576px) {
    .padding_baseMob {
      padding-block: calc(var(--space-md) * 2);
      padding-inline: var(--space);
    }
  }

  @media (min-width: 768px) {
    .padding_baseMob {
      padding-block: calc(var(--space) * 3);
    }
  }

  @media (min-width: 768px) {
    .padding_baseTab {
      padding-block: calc(var(--space) * 3);
    }
  }

  @media (min-width: 576px) {
    .padding_content {
      padding-block: calc(var(--space-md) * 2) var(--space-sm);
      padding-inline: var(--space-sm);
    }
  }

  @media (min-width: 768px) {
    .padding_content {
      padding-top: calc(var(--space) * 3);
    }
  }

  /* mob divider */

  @media (max-width: 575px) {
    .divider_mob::after {
      content: '';
      position: absolute;
      bottom: calc(-2 * var(--space-md));
      display: block;
      width: 100%;
      height: 1px;
      background: linear-gradient(
        90deg,
        rgb(from var(--primary-40) r g b / 0),
        var(--neutral-40) 50%,
        rgb(from var(--primary-40) r g b / 0)
      );
    }
  }

  /* Removed absolute positioning from this one so we can apply this if needed on components styles */

  .divider_full::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: linear-gradient(
      90deg,
      rgb(from var(--primary-40) r g b / 0),
      var(--neutral-40) 50%,
      rgb(from var(--primary-40) r g b / 0)
    );
  }
}
