
      @import 'styles-scss/abstracts/mixins';
    
.section {
  position: relative;
  padding-bottom: calc(var(--space-md) * 2);

  @include break-reverse(576) {
    padding-block: var(--space-xxl);
    padding-inline: var(--space);
    background-color: var(--primary-100);
    border: 1px solid var(--primary-60);
    border-radius: var(--space);
    box-shadow: inset 0 0 15px 0 rgb(from var(--neutral-25) r g b / 0.1);
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      inset: var(--space-xs);
      background-color: rgb(255, 255, 255, 0.01);
      background-image: url('./frame-lighting.svg');
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: top left;
      border-radius: calc(var(--space-lg) * 0.5);
      pointer-events: none;
    }
  }

  @include break-reverse(768) {
    padding-block: calc(var(--space) * 3);
    padding-inline: var(--space-md);
  }
}

.heading {
  margin-bottom: var(--space-xxl);

  @include break-reverse(768) {
    margin-bottom: var(--space-lg);
  }
}

.title,
.subtitle {
  max-width: 850px;
}

.title {
  margin-bottom: var(--space);

  @include break-reverse(768) {
    margin-bottom: var(--space-sm);
  }
}

.subtitle {
  margin-bottom: var(--space);
  font-weight: 500;
  color: var(--neutral-40);

  @include break-reverse(768) {
    margin-bottom: var(--space-md);
  }
}

.link {
  text-align: center;
  text-wrap: balance;
  font-size: var(--text-md);

  @include break-reverse(576) {
    font-size: var(--text-lg);
  }
}

.actions {
  position: relative;
  z-index: 2;
  flex-direction: column;
  margin-bottom: var(--space-md);

  @include break-reverse(768) {
    flex-direction: row;
    justify-content: center;
  }
}

.button_icon {
  transform: translate(0, -1px);
  width: 20px;
  height: 20px;
  stroke: var(--neutral-0);
  stroke-width: 1.5px;

  @include break-reverse(768) {
    order: -1;
  }
}

.visual {
  max-width: 1000px;
  margin-top: -4%;
  margin-bottom: calc(-1 * var(--space-xl));
  margin-inline: auto;
  pointer-events: none;

  @include break(767) {
    margin: 0 auto;
    width: fit-content;
    max-width: 480px;
  }

  @include break-reverse(768) {
    mask-image: linear-gradient(
        90deg,
        transparent 3%,
        #000 8%,
        #000 92%,
        transparent 97%
      ),
      linear-gradient(
        180deg,
        transparent 6%,
        #000 18%,
        #000 87%,
        transparent 96%
      );
    mask-size: 110% 110%;
    mask-position: center;
    mask-repeat: no-repeat;
    mask-composite: intersect;
  }
}

.video__desktop,
.video__mobile {
  video {
    margin: 0 auto;
  }
}

.video__desktop {
  @include break(767) {
    display: none;
  }
}

.video__mobile {
  @include break-reverse(767) {
    display: none;
  }
}
