
      @import 'styles-scss/abstracts/mixins';
    
.section {
  padding-block: calc(var(--space-md) * 2);

  @include break-reverse(768) {
    padding-block: calc(var(--space) * 3);
  }
}

.heading {
  margin-bottom: var(--space-xxl);

  @include break-reverse(768) {
    margin-bottom: calc(var(--space-md) * 2);
  }
}

.title {
  margin-bottom: var(--space);
}

.subtitle {
  max-width: 750px;
  color: var(--neutral-40);
}

.visual {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    width: 100%;
    height: 418px;
    opacity: 0.2;
    background: linear-gradient(
      rgb(from var(--neutral-55) r g b / 0.8) 18.5%,
      rgb(from var(--primary-100) r g b / 0) 100%
    );
    border-radius: 100%;
    filter: blur(50px);
  }

  @include break(767) {
    &::before {
      max-width: 490px;
    }
  }

  @include break-reverse(768) {
    &::before {
      width: 90%;
      height: 308px;
    }
  }

  @include break-reverse(1240) {
    &::before {
      width: 1000px;
    }
  }
}

.wrapper {
  position: relative;
  display: grid;
  align-content: start;
  justify-content: center;
  height: 490px;
  background-image: url('./bg-mob1x.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  mask-image: radial-gradient(ellipse, #000 0%, #000 60%, transparent 85%);
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 130% 115%;

  @include bg-image {
    background-image: url('./bg-mob2x.png');
  }

  @include break(767) {
    max-width: 490px;
    margin-inline: auto;
    padding-top: calc(var(--space) * 3);
  }

  @include break-reverse(768) {
    align-content: center;
    mask-size: 100% 190%;
    height: 390px;
    background-image: url('./bg-desk1x.png');

    @include bg-image {
      background-image: url('./bg-desk2x.png');
    }
  }

  @include break-reverse(1240) {
    mask-size: 1160px 720px;
  }
}

.counter {
  display: grid;
  justify-items: center;
  gap: var(--space-xxs);
  width: 202px;
  padding-block: var(--space-sm);
  background-color: var(--primary-100);
  border-radius: var(--space-sm);

  @include break-reverse(768) {
    gap: calc(var(--space) * 0.25);
    width: 308px;
    padding-block: var(--space-xl);
  }
}

.counter_text {
  font-weight: 500;

  @include break-reverse(768) {
    font-size: var(--header-xxs-20);
  }
}

.counter_data {
  padding: var(--space-xxs) var(--space-xs);
  background: rgb(from var(--primary-70) r g b / 0.4);
  border: 2px solid var(--primary-60);
  border-radius: calc(var(--space) * 0.5);
}

.counter_number {
  position: relative;
}

.counter_number__base,
.counter_number__main {
  font-family: var(--font-digital);
  font-size: var(--header-sm-28);

  @include break-reverse(768) {
    font-size: var(--header-xl-40);
  }
}

.counter_number__base {
  display: block;
  color: var(--primary-60);
}

.counter_number__main {
  position: absolute;
  top: 0;
  right: 0;
}

.actions {
  position: relative;
  flex-direction: column;
  margin-top: var(--space-xl);

  @include break-reverse(992) {
    flex-direction: row;
  }
}

// commented rules for future link
.link {
  //   max-width: 390px;
  //   width: 100%;
  text-align: center;
  text-wrap: balance;

  @include break-reverse(992) {
    // width: fit-content;
  }
}
