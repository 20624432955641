
      @import 'styles-scss/abstracts/mixins';
    
.visual {
  position: relative;
  margin-inline: auto;

  @include break(767) {
    max-width: 450px;
  }
}

.background {
  @include break-reverse(768) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 80%;
    height: 90%;
    background: linear-gradient(
      180deg,
      rgb(from var(--neutral-0) r g b / 0.3) 0%,
      rgb(from var(--neutral-55) r g b / 0.8) 36%,
      rgb(from var(--primary-100) r g b / 0) 100%
    );
    opacity: 0.05;
  }
}

.wrapper {
  margin-inline: auto;

  @include break-reverse(768) {
    max-width: 1100px;
  }
}

.image {
  display: block;
}
