
      @import 'styles-scss/abstracts/mixins';
    
.wrapper {
  position: relative;
}

.title {
  margin-bottom: var(--space-xxl);

  @include break-reverse(768) {
    margin-bottom: calc(var(--space-md) * 2);
  }
}

.visual {
  gap: var(--space);

  @include break-reverse(768) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }

  @include break-reverse(992) {
    gap: var(--space-lg);
  }
}

.block {
  gap: var(--space);

  @include break-reverse(992) {
    gap: var(--space-lg);
  }
}

.block__default {
  @include break(767) {
    max-width: 300px;
  }

  .block_visual {
    padding: calc(var(--space) * 0.25);

    @include break-reverse(768) {
      padding: calc(var(--space-lg) * 0.25);
    }
  }
}

.block__custom {
  @include break(767) {
    max-width: 480px;
  }

  .block_visual {
    padding: var(--space-xs);

    @include break-reverse(768) {
      padding: var(--space-sm);
    }
  }
}

.block_title {
  @include break-reverse(768) {
    font-size: var(--text-md);
  }

  @include break-reverse(992) {
    font-size: var(--header-xxs-20);
  }

  @include break-reverse(1240) {
    font-size: var(--header-xs-24);
  }
}

.block_visual {
  background: rgb(from var(--primary-70) r g b / 0.4);
}

.block_image {
  display: block;
}

.stroke {
  position: relative;
  display: flex;
  align-items: center;
  align-self: stretch;
  gap: var(--space-sm);

  &::before,
  &::after {
    content: '';
    display: block;
    max-width: 400px;
    width: 100%;
    height: 1px;
    border-top: 1px dashed var(--primary-40);
  }

  &::before {
    @include break-reverse(768) {
      display: none;
    }
  }

  &::after {
    @include break-reverse(768) {
      width: 1px;
      height: 100%;
      border-right: 1px dashed var(--primary-40);
    }
  }

  @include break(767) {
    width: 100%;
  }

  @include break-reverse(768) {
    flex-direction: column;
    gap: var(--space);
  }

  @include break-reverse(992) {
    gap: var(--space-lg);
  }
}

.text {
  position: relative;
  display: block;
  flex-shrink: 0;

  @include break(767) {
    top: -2px;
  }

  @include break-reverse(992) {
    font-size: var(--text-lg);
  }
}

.actions {
  flex-direction: column;
  margin-top: var(--space-lg);

  @include break-reverse(768) {
    flex-direction: row;
  }
}
