
      @import 'styles-scss/abstracts/mixins';
    
.visual {
  position: relative;
}

.background {
  position: absolute;

  @include break-reverse(768) {
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 100%;
    height: auto;
    aspect-ratio: 12;
    background: linear-gradient(
      180deg,
      rgb(from var(--neutral-0) r g b / 0.2) 0%,
      rgb(from var(--neutral-55) r g b / 0.8) 50%,
      rgb(from var(--neutral-0) r g b / 0.2) 100%
    );
    border-radius: 100%;
    opacity: 0.25;
    filter: blur(60px);
  }

  @include break-reverse(992) {
    width: 900px;
    filter: blur(100px);
  }
}

.wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  position: relative;
  display: block;
  max-width: 450px;

  @include break-reverse(768) {
    max-width: 900px;
  }
}
