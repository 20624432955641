
      @import 'styles-scss/abstracts/mixins';
    
.section {
  position: relative;
  padding-block: calc(var(--space-md) * 2);

  @include break-reverse(768) {
    padding-block: calc(var(--space) * 3);
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    display: block;
    width: 100%;
    height: 1px;
    background: linear-gradient(
      90deg,
      rgb(from var(--primary-40) r g b / 0),
      var(--neutral-40) 50%,
      rgb(from var(--primary-40) r g b / 0)
    );
  }
}

.heading {
  margin-bottom: var(--space-xxl);

  @include break-reverse(768) {
    margin-bottom: var(--space-xl);
  }
}

.title {
  margin-bottom: var(--space);
}

.subtitle {
  color: var(--neutral-40);
}

.actions {
  margin-top: var(--space-xxl);

  @include break-reverse(768) {
    margin-top: var(--space-lg);
  }
}

.link {
  max-width: 400px;
  text-align: center;
}
