
      @import 'styles-scss/abstracts/mixins';
    
.page {
  background: var(--primary-100);
  overflow-x: clip;
}

.page_wrapper {
  @include break-reverse(576) {
    border: 1px solid var(--primary-60);
    border-radius: 24px;
    padding: var(--space);
    box-shadow: inset 0 0 15px 0 rgb(from var(--neutral-25) r g b / 0.1);
    background-image: url('./wrapper-lighting.svg');
    background-size: 100%;
    background-position: bottom center;
    background-repeat: no-repeat;
    overflow: hidden;
  }

  @include break-reverse(768) {
    padding: var(--space-xl) var(--space-lg);
  }
}
